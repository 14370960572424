import React from "react";
import { Layout } from "antd";
import { connect } from "react-redux";
import {
	SIDE_NAV_WIDTH,
	SIDE_NAV_DARK,
	NAV_TYPE_SIDE,
} from "constants/ThemeConstant";
import { Scrollbars } from "react-custom-scrollbars";
import MenuContent from "./MenuContent";
import NavProfile from "./NavProfile";

const { Sider } = Layout;

export const SideNav = ({
	navCollapsed,
	sideNavTheme,
	routeInfo,
	hideGroupTitle,
	localization = true,
}) => {
	const props = { sideNavTheme, routeInfo, hideGroupTitle, localization };


	return (
		<Sider
			className={`side-nav ${
				sideNavTheme === SIDE_NAV_DARK ? "side-nav-dark" : ""
			}`}
			width={SIDE_NAV_WIDTH}
			collapsed={navCollapsed}
		>
			<div
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Scrollbars>
					<MenuContent type={NAV_TYPE_SIDE} {...props} />
				</Scrollbars>
				<div
					style={{
						width: "100%",
						display: "flex",
						justifyContent: "center",
						marginTop: 10,
						marginBottom: 8,
					}}
				>
					<NavProfile navCollapsed={navCollapsed} />
				</div>
			</div>
		</Sider>
	);
};

const mapStateToProps = ({ theme }) => {
	const { navCollapsed, sideNavTheme } = theme;
	return { navCollapsed, sideNavTheme };
};

export default connect(mapStateToProps)(SideNav);

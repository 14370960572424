/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Menu, Avatar } from "antd";
import { connect } from "react-redux";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { signOut } from "redux/actions/Auth";
import EllipsisDropdown from "components/shared-components/EllipsisDropdown";

// const menuItem = [];

export const NavProfile = ({ signOut, user }) => {
	/* 	const profileMenu = (
		<div className="nav-profile nav-dropdown">
			<div className="nav-profile-header">
				<div className="d-flex">
					<UserOutlined style={{ fontSize: 45 }} />
					<div className="pl-3">
						<h4 className="mb-0">{user.name}</h4>
						<span className="text-muted">{user.role}</span>
					</div>
				</div>
			</div>
			<div className="nav-profile-body">
				<Menu>
					{menuItem.map((el, i) => {
						return (
							<Menu.Item key={i}>
								<a href={el.path}>
									<Icon className="mr-3" type={el.icon} />
									<span className="font-weight-normal">
										{el.title}
									</span>
								</a>
							</Menu.Item>
						);
					})}
					<Menu.Item key={0}>
						<a onClick={() => requestCacheService.limparCache()}>
							<ClearOutlined className="mr-3" />
							<span className="font-weight-normal">
								Limpar cache
							</span>
						</a>
					</Menu.Item>
					<Menu.Item
						key={menuItem.length + 1}
						onClick={(e) => signOut()}
					>
						<span>
							<LogoutOutlined className="mr-3" />
							<span className="font-weight-normal">Sair</span>
						</span>
					</Menu.Item>
				</Menu>
			</div>
		</div>
	); */
	return (
		<div className="nav-profile-header">
			<div className="d-flex" style={{ alignItems: "center" }}>
				<Avatar
					style={{
						marginLeft: 10,
						color: "#0038B4",
						backgroundColor: "#f3f6fc",
						fontSize: 17,
						fontWeight: "bold",
					}}
				>
					<UserOutlined
						style={{
							color: "black",
							width: "100%",
							fontSize: "20px",
						}}
						className="nav-icon mr-0"
					/>
				</Avatar>
				<span style={{ color: "#00325E", fontWeight: 500 }}>
					{user.name}
				</span>
				<EllipsisDropdown
					placement={"topLeft"}
					menu={
						<Menu>
							<Menu.Item key="0" onClick={(e) => signOut()}>
								<div style={{ display: "flex" }}>
									<div style={{ flexGrow: 1 }}>
										<span>Sair</span>
									</div>
									<div>
										<LogoutOutlined />
									</div>
								</div>
							</Menu.Item>
						</Menu>
					}
				/>
			</div>
		</div>
	);
};

const mapStateToProps = ({ user, theme }) => {
	const { navCollapsed, sideNavTheme } = theme;
	return { user: { ...user }, navCollapsed, sideNavTheme };
};

export default connect(mapStateToProps, { signOut })(NavProfile);

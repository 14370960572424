// TODO: após clonar o skeleton, ajustar os links de ambientes

const dev = {
	API_ENDPOINT_URL: "http://localhost:8093/vendamais/api",
	PUBLIC_PREFIX_PATH: "",
};

const prod = {
	API_ENDPOINT_URL:
		"https://vendamais-back-9ujsq.ondigitalocean.app/vendamais-back2/vendamais/api",
	PUBLIC_PREFIX_PATH: "",
};

const test = {
	API_ENDPOINT_URL: "https://homolog.dimo.com.br/vendamais-back/api",
	PUBLIC_PREFIX_PATH: "/vendamais",
};

const getEnv = () => {
	switch (process.env.REACT_APP_ENV) {
		case "development":
			return dev;
		case "production":
			return prod;
		case "test":
			return test;
		default:
			return dev;
	}
};

export const env = getEnv();

import { UPDATE_PERMISSOES } from "../constants/Permissoes";

const initPermissoes = {};

const permissoes = (state = initPermissoes, action) => {
  switch (action.type) {
    case UPDATE_PERMISSOES:
      return {
        ...action.permissoes,
      };
    default:
      return state;
  }
};

export default permissoes;

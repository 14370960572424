import fetch from "auth/FetchInterceptor";
import store from "redux/store";
import { UPDATE_NOTIFICATIONS } from "redux/constants/Menu";
import { UPDATE_PERMISSOES } from "redux/constants/Permissoes";
import { UPDATE_USER } from "redux/constants/User";

const MenuService = {};

/*
    Injeta na store do usuário os dados do menu
*/
MenuService.populateMenuData = async function () {
  const menuData = await MenuService.getMenuData();

  store.dispatch({
    type: UPDATE_USER,
    name: menuData.usuario.nome,
    role: "Usuário",
  });

  store.dispatch({
    type: UPDATE_NOTIFICATIONS,
    empresasComErros: menuData.notificacoes.total_empresas_com_erros,
  });

  store.dispatch({
    type: UPDATE_PERMISSOES,
    permissoes: menuData.permissoes,
  });
};

// TODO: após clonar o skeleton, ajustar essa função
MenuService.getMenuData = function () {
  return fetch({
    url: "/v1/rpc/menu",
    method: "get",
  });
};

export default MenuService;

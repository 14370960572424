export const PermissaoRepresentanteComercialEnum = {
	VISUALIZAR: "VISUALIZAR_REPRESENTANTE_COMERCIAL",
	CRIAR: "CRIAR_REPRESENTANTE_COMERCIAL",
	EXCLUIR: "EXCLUIR_REPRESENTANTE_COMERCIAL",
};

export const PermissaoGrupoUsuarioEnum = {
	VISUALIZAR: "VISUALIZAR_GRUPO_USUARIO",
	CRIAR: "CRIAR_GRUPO_USUARIO",
	EXCLUIR: "EXCLUIR_GRUPO_USUARIO",
};

export const PermissaoFornecedorEnum = {
	VISUALIZAR: "VISUALIZAR_FORNECEDOR",
	CRIAR: "CRIAR_FORNECEDOR",
	EXCLUIR: "EXCLUIR_FORNECEDOR",
};
export const PermissaoRegionalEnum = {
	VISUALIZAR: "VISUALIZAR_REGIONAL",
	CRIAR: "CRIAR_REGIONAL",
	EXCLUIR: "EXCLUIR_REGIONAL",
};
export const PermissaoClienteEnum = {
	VISUALIZAR: "VISUALIZAR_CLIENTE",
	VISUALIZAR_TODOS: "VISUALIZAR_TODOS_CLIENTE",
	CRIAR: "CRIAR_CLIENTE",
};
export const PermissaoProdutoEnum = {
	VISUALIZAR: "VISUALIZAR_PRODUTO",
	CRIAR: "CRIAR_PRODUTO",
};
export const PermissaoTipoConsumoEnum = {
	VISUALIZAR: "VISUALIZAR_TIPO_CONSUMO",
	CRIAR: "CRIAR_TIPO_CONSUMO",
	EXCLUIR: "EXCLUIR_TIPO_CONSUMO",
};
export const PermissaoCampanhaEnum = {
	VISUALIZAR: "VISUALIZAR_CAMPANHA",
	CRIAR: "CRIAR_CAMPANHA",
};
export const PermissaoPedidoEnum = {
	VISUALIZAR: "VISUALIZAR_PEDIDO",
	CRIAR: "CRIAR_PEDIDO",
};
export const PermissaoVisitaEnum = {
	VISUALIZAR: "VISUALIZAR_VISITA",
	CRIAR: "CRIAR_VISITA",
};

export const PermissaoAgendaEnum = {
	VISUALIZAR: "VISUALIZAR_AGENDA",
	CRIAR: "CRIAR_AGENDA",
	EXCLUIR: "EXCLUIR_AGENDA",
};
export const PermissaoRelatorioEnum = {
	GERAR: "GERAR_RELATORIOS",
};

export const PermissaoDashboardEnum = {
	VISUALIZAR: "VISUALIZAR_DASHBOARD",
};

export const PermissaoClienteParceiroEnum = {
	VISUALIZAR: "VISUALIZAR_CLIENTE_PARCEIRO",
	CRIAR: "CRIAR_CLIENTE_PARCEIRO",
	EXCLUIR: "EXCLUIR_CLIENTE_PARCEIRO",
};

import { APP_PREFIX_PATH } from "configs/AppConfig";
import {
	CampanhasSVG,
	ClientesSVG,
	FornecedoresSVG,
	ProdutosSVG,
	RegionaisSVG,
	RepresentantesComercialSVG,
	VisaoGeralSVG,
	ClientesParceirosSVG,
	GrupoUsuarioSVG,
} from "../assets/svg/icon";
import {
	PermissaoCampanhaEnum,
	PermissaoClienteEnum,
	PermissaoDashboardEnum,
	PermissaoFornecedorEnum,
	PermissaoClienteParceiroEnum,
	PermissaoProdutoEnum,
	PermissaoRegionalEnum,
	PermissaoRepresentanteComercialEnum,
	PermissaoGrupoUsuarioEnum,
} from "enum/permissao-enum";
import { TableOutlined, FilePdfOutlined } from "@ant-design/icons";

const dashBoardNavTree = [
	{
		key: "visao-geral",
		path: `${APP_PREFIX_PATH}/visao-geral`,
		title: "visao-geral",
		icon: VisaoGeralSVG,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoDashboardEnum.VISUALIZAR,
	},
	{
		key: "lista-grupos-usuario",
		path: `${APP_PREFIX_PATH}/lista-grupos-usuario`,
		title: "lista-grupos-usuario",
		icon: GrupoUsuarioSVG,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoGrupoUsuarioEnum.VISUALIZAR,
	},
	{
		key: "lista-produtores",
		path: `${APP_PREFIX_PATH}/lista-produtores`,
		title: "lista-produtores",
		icon: ClientesSVG,
		breadcrumb: false,
		permissao: PermissaoClienteEnum.VISUALIZAR,
		submenu: [],
	},
	{
		key: "lista-cliente-parceiro",
		path: `${APP_PREFIX_PATH}/lista-cliente-parceiro`,
		title: "lista-cliente-parceiro",
		icon: ClientesParceirosSVG,
		breadcrumb: false,
		permissao: PermissaoClienteParceiroEnum.VISUALIZAR,
		submenu: [],
	},
	{
		key: "lista-fornecedores",
		path: `${APP_PREFIX_PATH}/lista-fornecedores`,
		title: "lista-fornecedores",
		icon: FornecedoresSVG,
		breadcrumb: false,
		permissao: PermissaoFornecedorEnum.VISUALIZAR,
		submenu: [],
	},
	{
		key: "lista-produtos",
		path: `${APP_PREFIX_PATH}/lista-produtos`,
		title: "lista-produtos",
		icon: ProdutosSVG,
		breadcrumb: false,
		permissao: PermissaoProdutoEnum.VISUALIZAR,
		submenu: [],
	},
	{
		key: "lista-campanhas",
		path: `${APP_PREFIX_PATH}/lista-campanhas`,
		title: "lista-campanhas",
		icon: CampanhasSVG,
		breadcrumb: false,
		permissao: PermissaoCampanhaEnum.VISUALIZAR,
		submenu: [],
	},
	{
		key: "lista-promotor-tecnico",
		path: `${APP_PREFIX_PATH}/lista-promotor-tecnico`,
		title: "lista-promotor-tecnico",
		icon: RepresentantesComercialSVG,
		breadcrumb: false,
		permissao: PermissaoRepresentanteComercialEnum.VISUALIZAR,
		submenu: [],
	},
	{
		key: "lista-regionais",
		path: `${APP_PREFIX_PATH}/lista-regionais`,
		title: "lista-regionais",
		icon: RegionaisSVG,
		breadcrumb: false,
		permissao: PermissaoRegionalEnum.VISUALIZAR,
		submenu: [],
	},

	{
		showTitle: true,
		title: "relatorios",
		submenu: [
			{
				key: "relatorios-xls",
				path: `${APP_PREFIX_PATH}/relatorios-xls`,
				title: "relatorios-xls",
				icon: TableOutlined,
				breadcrumb: false,
				submenu: [],
				//permissao: PermissaoDashboardEnum.GERAR,
			},
			{
				key: "relatorios-pdf",
				path: `${APP_PREFIX_PATH}/relatorios-pdf`,
				title: "relatorios-pdf",
				icon: FilePdfOutlined,
				breadcrumb: false,
				submenu: [],
				//permissao: PermissaoDashboardEnum.GERAR,
			},
		],
	},

	/* {
		showTitle: true,
		title: "cadastros",
		submenu: [
			{
				key: "cadastro-cliente",
				path: `${APP_PREFIX_PATH}/cadastro-cliente`,
				title: "cadastro-cliente",
				icon: ClientesSVG,
				breadcrumb: false,
				permissao: PermissaoClienteEnum.CRIAR,
				submenu: [],
			},
			{
				key: "cadastro-produto",
				path: `${APP_PREFIX_PATH}/cadastro-produto`,
				title: "cadastro-produto",
				icon: ProdutosSVG,
				breadcrumb: false,
				permissao: PermissaoProdutoEnum.CRIAR,
				submenu: [],
			},
			{
				key: "cadastro-campanha",
				path: `${APP_PREFIX_PATH}/cadastro-campanha`,
				title: "cadastro-campanha",
				icon: CampanhasSVG,
				breadcrumb: false,
				permissao: PermissaoCampanhaEnum.CRIAR,
				submenu: [],
			},
			{
				key: "cadastro-representante-comercial",
				path: `${APP_PREFIX_PATH}/cadastro-representante-comercial`,
				title: "cadastro-representante-comercial",
				icon: RepresentantesComercialSVG,
				breadcrumb: false,
				permissao: PermissaoRepresentanteComercialEnum.CRIAR,
				submenu: [],
			},
			{
				key: "cadastro-fornecedor",
				path: `${APP_PREFIX_PATH}/cadastro-fornecedor`,
				title: "cadastro-fornecedor",
				icon: FornecedoresSVG,
				breadcrumb: false,
				permissao: PermissaoFornecedorEnum.CRIAR,
				submenu: [],
			},
			{
				key: "cadastro-regional",
				path: `${APP_PREFIX_PATH}/cadastro-regional`,
				title: "cadastro-regional",
				icon: RegionaisSVG,
				breadcrumb: false,
				permissao: PermissaoRegionalEnum.CRIAR,
				submenu: [],
			},
		],
	}, */

	//exemplo com permissão
	// {
	// 	key: "lista-usuarios",
	// 	path: `${APP_PREFIX_PATH}/lista-usuarios`,
	// 	title: "lista-usuarios",
	// 	icon: UserOutlined,
	// 	breadcrumb: false,
	// 	submenu: [],
	// 	permissao: PermissaoUsuarioEnum.VISUALIZAR,
	// },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;

import { UPDATE_NOTIFICATIONS } from "redux/constants/Menu";

const initMenu = {
  empresasComErros: 0,
};

const menu = (state = initMenu, action) => {
  switch (action.type) {
    case UPDATE_NOTIFICATIONS:
      return {
        ...state,
        empresasComErros: action.empresasComErros,
      };
    default:
      return state;
  }
};

export default menu;
